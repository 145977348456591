import React, { useEffect, useState } from 'react'
import { Trans, useTranslation }      from 'react-i18next'
import {
  Box,
  Button, Checkbox,
  Container, FormControl,
  FormControlLabel, FormGroup,
  Grid, Input,  InputLabel, Link,
  Paper, Stack, TextField, Typography,
}                                                     from '@mui/material'
import { useApi }                                     from '../utils/api'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar }                                from 'notistack'
import ShortFormControl                               from '../ui/components/short-form-control'
import AlertSimpleDialog                              from '../ui/components/alert-simple-dialog'
import BaseTable, { BaseSearch }                      from '../ui/components/base-table'
import Iconify                                        from '../ui/components/Iconify'
import { BaseBreadcrumbs }                            from '../ui/components/BaseBreadcrumbs'
import Page                                           from '../ui/components/Page'
import Consts                                         from '../consts'
import Label                                          from '../ui/components/Label'
import useCities                                      from '../utils/useCities'

export default function CityPage () {

  const api = useApi()
  const navigate = useNavigate()
  const { cityId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [values, setValues] = useState({
    name: '',
    latitude: null,
    longitude: null,
    vatEnabled: false,
    vatRate: 23,
    billingInfo: '',
    flags: 0,
  })
  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)
  const [_, reloadCities] = useCities()

  useEffect(() => {
    (async () => {
      if (!isNaN(cityId)) {
        let city = await api.city.detail(cityId).catch(() => ({}))
        setValues({
          ...city,
          vatRate: city.vatRate ? (city.vatRate * 100) : 0
        })
      }
    })()
  }, [cityId])

  const handleCloseDeleteDialog = (result) => {
    setShownDeleteDialog(false)
    if (result) {
      api.city.delete(cityId).then(() => {
        enqueueSnackbar(t('City deleted'), {
          variant: 'success'
        })
        navigate('/cities')
      }).catch(error => {
        enqueueSnackbar(t('Failed to delete city'), {
          variant: 'error'
        })
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = {
      ...values,
      vatRate: values.vatRate ? (values.vatRate / 100) : 0,
      flags: (values.vatEnabled ? Consts.City.flagVatEnabled : 0),
    }
    try {
      let city = await api.city.save(data)
      navigate(`/cities/${city.id}`)
      enqueueSnackbar(t('City saved'), {
        variant: 'success'
      })
      reloadCities()
    } catch (e) {
      enqueueSnackbar(t('Failed to save city'), {
        variant: 'error'
      })
    }
  }

  const handleChange = (prop) => (event) => {
    handleChangeDirect(prop, event.target.value)
  }

  const handleChangeDirect = (prop, value) => {
    setValues({ ...values, [prop]: value })
  }

  return (
    <Page title={t('City')}>
      <Container component="main">
        <BaseBreadcrumbs key={'breadcrumb-city'}
                         title={isNaN(cityId) ? t('Create new city') : (`${t('City')} - ${values.name}`)}
                         items={[
                           { href: `/cities`, title: t('Cities') },
                         ]} />
        <Grid component={'form'} onSubmit={onSubmit} item mb={4} xs={12}>
          <Paper sx={{ p: 2, width: '100%' }}>
            <Grid container sx={{ width: '100%' }} spacing={2}>
              <Grid item xs={12}>
                <ShortFormControl
                  name={'name'}
                  title={t('Title')}
                  value={values.name}
                  handleChange={handleChange}/>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <ShortFormControl
                    name={'latitude'}
                    title={t('Latitude')}
                    value={values.latitude ?? ''}
                    handleChange={handleChange}
                    inputProps={{
                      type: 'number',
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <ShortFormControl
                    name={'longitude'}
                    title={t('Longitude')}
                    value={values.longitude ?? ''}
                    handleChange={handleChange}
                    inputProps={{
                      type: 'number',
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox/>} checked={values.vatEnabled} onChange={(e) => {
                    handleChangeDirect('vatEnabled', e.target.checked)
                  }} label={t('Vat enabled')}/>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormGroup>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="vat-rate">{t('Vat %')}</InputLabel>
                    <Input
                      id="vat-rate"
                      type={'number'}
                      value={values?.vatRate ?? ''}
                      onChange={handleChange('vatRate')}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    id="info-billingInfo"
                    label={t('Billing info')}
                    multiline
                    rows={6}
                    variant="standard"
                    value={values.billingInfo}
                    onChange={handleChange('billingInfo')}
                    sx={{ width: '100%' }}/>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          <Box sx={{ mt: 2, px: 1 }}>
            <Button type={'submit'} variant={'contained'}>{t('Save city')}</Button>
            {!isNaN(cityId) && (<Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }}
                                        onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>)}
          </Box>
        </Grid>
        <AlertSimpleDialog
          title={t('Delete zone')}
          message={t('delete-confirm-msg', { name: values.name })}
          open={shownDeleteDialog}
          onClose={handleCloseDeleteDialog}
        />
      </Container>
    </Page>
  )

}