import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button, Collapse,
  Container, FormControl, Grid, IconButton, InputLabel,
  Link, MenuItem, Select,
  Stack, TextField,
  Typography
}                                     from '@mui/material'
import { useApi }                        from '../utils/api'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import Page                      from '../ui/components/Page'
import BaseTable, { BaseSearch } from '../ui/components/base-table'
import { fDateTime }             from '../utils/textutils'
import { BaseBreadcrumbs }       from '../ui/components/BaseBreadcrumbs'
import ShortFormControl          from '../ui/components/short-form-control'
import { DateTime }              from 'luxon'
import palette                   from '../ui/theme/palette'
import Loader                    from '../ui/components/Loader'
import Iconify                   from '../ui/components/Iconify'
import { useSnackbar }           from 'notistack'
import Label                     from '../ui/components/Label'

export default function TicketsPage() {

  const api = useApi()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [filterOpen, setFilterOpen] = useState(true)
  const { query, from, to, cityIds, cityServicesIds } = useParams()
  const [cities, setCities] = useState([])
  const [cityServices, setCityServices] = useState([])
  const [filter, setFilter] = useState({
    dateFrom: from ?? DateTime.now().toFormat('yyyy-MM-dd'),
    dateTo: to ?? DateTime.now().toFormat('yyyy-MM-dd'),
    selectedCities: [],
    selectedCityServices: [],
    query: query ?? '',
  })
  const [shownFilter, setShownFilter] = useState({
    dateFrom: from ?? DateTime.now().toFormat('yyyy-MM-dd'),
    dateTo: to ?? DateTime.now().toFormat('yyyy-MM-dd'),
    selectedCities: [],
    selectedCityServices: [],
    query: query ?? '',
  })
  const [baseTableQuery, setBaseTableQuery] = useState('{}')

  useEffect(() => {
    setBaseTableQuery(JSON.stringify(filter))
    api.city.list().then(({data}) => {
      setCities(data)
      const selectedCities = (cityIds ?? '').split(',')?.map(it => parseInt(it))?.map(cityId => data.find(it => it.id === cityId))?.filter(it => !!it) ?? []
      setFilter((prev) => ({
        ...prev,
        selectedCities: selectedCities
      }))
      setShownFilter((prev) => ({
        ...prev,
        selectedCities: selectedCities
      }))
      const selectedcityServicesIds = (cityServicesIds ?? '').split(',')?.map(it => parseInt(it)) ?? []
      if (selectedCities.length === 1 && selectedcityServicesIds.length > 0) {
        api.cityService.list(selectedCities[0].id).then(({cityServices}) => {
          const selectedCityServicesObjects = selectedcityServicesIds.map(it => cityServices.find(cs => cs.id === it)).filter(it => !!it)
          setFilter((prev) => ({
            ...prev,
            selectedCityServices: selectedCityServicesObjects
          }))
          setShownFilter((prev) => ({
            ...prev,
            selectedCityServices: selectedCityServicesObjects
          }))
        })
      }
    }).catch(e => {
      enqueueSnackbar(t('Failed to load cities'), {
        variant: 'error'
      })
    })
  }, [])

  useEffect((prev) => {
    if (filter.selectedCities.length === 1) {
      api.cityService.list(filter.selectedCities[0].id).then(({ data }) => {
        setCityServices(data)
      }).catch(error => {
        enqueueSnackbar(t('Failed to load city services'), {
          variant: 'error'
        })
      })
    } else {
      setFilter((prev) => ({
        ...prev,
        selectedCityServices: [],
      }))
      setCityServices([])
    }
  }, [filter.selectedCities])

  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, sortable: false, filterable: false },
    { field: 'name', headerName: t('Customer phone number'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => (
        <Link
          component={RouterLink}
          variant="body2"
          to={`/tickets/${cell.row.id}`}
        >
          {cell.row.telNumber}
        </Link>
      )},
    { field: 'city', headerName: t('City'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => (
        <Link
          component={RouterLink}
          variant={'body2'}
          to={`/cities/${cell.row.city.id}`}>
          {cell.row.city?.name ?? '---'}
        </Link>
      )},
    { field: 'cityService', headerName: t('City service'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => (
        <Link
          component={RouterLink}
          variant="body2"
          to={`/city-services/${cell.row.cityService.id}`}
        >
          {cell.row.cityService?.name ?? '---'}
        </Link>
      )},
    { field: 'ticketMessage', headerName: t('Delivery status'), width: 120, sortable: false, filterable: false, renderCell: (cell) => {
      const icon = cell.row.ticketMessage.deliveryStatus === 'delivered' ? 'material-symbols:bookmark-check' : (cell.row.ticketMessage.deliveryStatus === 'sent' ? 'material-symbols:question-exchange' : 'material-symbols:exclamation')
      const color = cell.row.ticketMessage.deliveryStatus === 'delivered' ? 'success' : (cell.row.ticketMessage.deliveryStatus === 'sent' ? 'secondary' : 'warning')
      return <Label color={color} startIcon={<Iconify icon={icon}/>}>{t(`deliverystatus-${cell.row.ticketMessage.deliveryStatus}`)}</Label>
    }},
    { field: 'validFrom', headerName: t('Validity'), flex: 2, sortable: false, filterable: false, renderCell: (cell) => `${fDateTime(cell.row.validFrom)} - ${fDateTime(cell.row.validTo)}` },
  ];

  const onSearchSubmit = (e) => {
    e.preventDefault()
    setBaseTableQuery(JSON.stringify(filter))
    setShownFilter(filter)
  }

  const makeFilterTitle = () => {
    const reqDateFrom = (shownFilter.dateFrom ? DateTime.fromFormat(shownFilter.dateFrom, 'yyyy-MM-dd') : DateTime.now()).toFormat('dd.MM.yyyy')
    const reqDateTo = (shownFilter.dateTo ? DateTime.fromFormat(shownFilter.dateTo, 'yyyy-MM-dd') : DateTime.now()).toFormat('dd.MM.yyyy')
    const reqQuery = shownFilter.query ? shownFilter.query : "''"
    const cityServices = shownFilter.selectedCityServices.length > 0 ? shownFilter.selectedCityServices.map(z => z.name).join(', ') : t('All')
    const cities = shownFilter.selectedCities.length > 0 ? shownFilter.selectedCities.map(z => z.name).join(', ') : t('All')
    return <Trans i18nKey={'ticketsFilterHighlight'} values={{ from: reqDateFrom, to: reqDateTo, cities: cities, cityServices: cityServices, query: reqQuery }} />
  }

  return (<Page title={t('Tickets')}>
    <Container>
      <BaseBreadcrumbs key={'breadcrumb-tickets'}
                       title={t('Tickets')}
                       items={[]}/>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2} alignItems={'baseline'} mb={2}>
            <Typography variant={'h6'}>{t('Tickets')}</Typography>
            <Typography variant="body1" sx={{ color: palette.text.disabled }}>
              {makeFilterTitle()}
            </Typography>
            <Box flexGrow={1}/>
            <IconButton onClick={(e) => setFilterOpen(prev => !prev)}>
              <Iconify icon={filterOpen ? 'material-symbols:filter-alt-outline' : 'material-symbols:filter-alt'}/>
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
      <Collapse in={filterOpen}>
        <Grid container component={'form'} onSubmit={onSearchSubmit} spacing={2} rowSpacing={2} direction={'row'} alignItems={'flex-end'} justifyContent={'flex-end'} mb={2}>
          <Grid container item spacing={2} direction={'row'} alignItems={'flex-end'} justifyContent={'flex-end'} >
            <Grid item xs={6} sm={'auto'}>
              <ShortFormControl title={t('From')} handleChange={(name) => (e) => { setFilter((prev) => ({
                ...prev,
                dateFrom: e.target?.value
              })) }} value={filter.dateFrom} name={'from'} inputProps={{ type: 'date' }} />
            </Grid>
            <Grid item xs={6} sm={'auto'}>
              <ShortFormControl title={t('To')} handleChange={(name) => (e) => { setFilter((prev) => ({
                ...prev,
                dateTo: e.target?.value
              })) }} value={filter.dateTo} name={'to'} inputProps={{ type: 'date' }} />
            </Grid>
            <Grid item xs={12} sm={'auto'} sx={{ minWidth: { sm: '200px' } }}>
              <ShortFormControl title={t('Search...')} handleChange={(name) => (e) => { setFilter((prev) => ({
                ...prev,
                query: e.target?.value
              })) }} value={filter.query} name={'query'} inputProps={{ type: 'text' }} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={'auto'} sx={{ minWidth: { sm: '200px' } }}>
            <Autocomplete
              multiple
              fullWidth
              id="tags-cities"
              options={cities}
              limitTags={3}
              getOptionLabel={(option) => option.name}
              value={filter.selectedCities}
              onChange={(e, newValue) => {
                setFilter((prev) => ({
                  ...prev,
                  selectedCities: newValue
                }))
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t('Cities')}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={'auto'} sx={{ minWidth: { sm: '200px' } }}>
            <Autocomplete
              multiple
              fullWidth
              id="tags-city-services"
              options={cityServices}
              limitTags={5}
              disabled={(filter.selectedCities ?? []).length !== 1}
              getOptionLabel={(option) => option.name}
              value={filter.selectedCityServices}
              onChange={(e, newValue) => {
                setFilter((prev) => ({
                  ...prev,
                  selectedCityServices: newValue
                }))
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t('City services')}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ maxWidth: { sm: '200px' } }}>
            <Button fullWidth type={'submit'} variant="outlined">{t('SearchAction')}</Button>
          </Grid>
        </Grid>
      </Collapse>
      <BaseTable
        columns={columns}
        query={baseTableQuery}
        loadCallback={async (query, page, pageSize) => {
          const queryObject = JSON.parse(query)
          const dateFrom = queryObject.dateFrom?.replaceAll('-','')
          const dateTo = queryObject.dateTo?.replaceAll('-','')
          const cityIds = (queryObject.selectedCities?.length ?? 0) > 0 ? queryObject.selectedCities.map(it => it.id) : null
          const cityServicesIds = (queryObject.selectedCityServices?.length ?? 0) > 0 ? queryObject.selectedCityServices.map(it => it.id) : null
          try {
            return await api.ticket.list(null, queryObject.query, dateFrom, dateTo, cityIds, cityServicesIds, page, pageSize)
          } catch (e) {
            console.log('Failed to load tickets', e)
            return []
          }
        }}
      />
    </Container>
  </Page>)

}